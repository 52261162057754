<template>
  <v-row>
    <v-alert
      v-model="filenameErr"
      dismissible
      class="w-full ma-1"
      type="error"
      transition="slide-y-transition"
      elevation="2"
      style="position: absolute;z-index: 100000; max-width: 380px; right: 8px"
    >
      {{ filenameErrMsg }}
    </v-alert>

    <v-dialog
      v-model="newFileDialog"
      max-width="500px"
      @click:outside="setNewFileDialog(false)"
      @keydown="setNewFileDialog(false)"
    >
      <v-card>
        <v-card-title>New File</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list
            v-for="(newFileTypesArr, i) in newFileTypes"
            :key="i"
            nav
            dense
          >
            <v-divider
              v-if="i !== 0"
              class="mb-4"
            ></v-divider>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, j) in newFileTypesArr"
                :key="j"
                @click="item.disabled ? () => {} : item.action()"
              >
                <v-list-item-icon v-bind="{disabled: item.disabled}">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content v-bind="{disabled: item.disabled}">
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            @click="setNewFileDialog(false)"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="newLinkFileDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>{{ editingFile ? 'Edit ' + editingFile.name : 'New Link File' }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="newFileName"
            class="mt-4"
            outlined
            label="File Name"
            autofocus
          ></v-text-field>
          <v-text-field
            v-model="newFileLink"
            class="mt-4"
            outlined
            label="Link"
            autocomplete="false"
          ></v-text-field>
          <v-autocomplete
            v-model="newFileType"
            outlined
            dense
            label="Type"
            :items="fileTypes"
            item-text="name"
            item-value="type"
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-icon style="padding-top: 2px">
                  <v-icon
                    small
                    v-text="data.item.icon"
                  ></v-icon>
                </v-list-item-icon>
                <v-list-item-content class="pl-1">
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            @click="closeLinkDialog"
          >
            Close
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="editingFile ? saveFileEditingAction() : addLinkNewFileAction()"
          >
            {{ editingFile ? 'Save' : 'Add' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="newGoogleFileDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>New Link File</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="newFileName"
            class="mt-4"
            outlined
            label="File Name"
            autofocus
          ></v-text-field>
          <div class="mt-0">
            <b>Note:</b> You'll be redirected to new document page and once it created and saved you'll need to put url in <b>Link</b> box below.
          </div>
          <div class="mt-4 mb-6">
            <a @click="redirectToNewGoogleDocument">
              Open New Google {{ newGoogleDocumentNames[newGoogleDocumentType] || 'Document' }}
              <v-icon small>{{ icons.mdiArrowTopRight }}</v-icon>
            </a>
          </div>
          <v-text-field
            v-model="newFileLink"
            class="mt-4"
            outlined
            label="Link"
            autocomplete="false"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            @click="newGoogleFileDialog = false; setNewFileDialog(true)"
          >
            Close
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="addLinkNewFileAction"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  mdiArrowLeft,
  mdiFile,
  mdiFolder,
  mdiDelete,
  mdiPlus,
  mdiFileDocument,
  mdiGoogleSpreadsheet,
  mdiLink,
  mdiArrowTopRight,
  mdiDrawingBox,
  mdiPencilBox,
} from '@mdi/js'
import { mapState } from 'vuex'
import { openNewTab } from '@/helpers/helpers'

export default {
  components: {

  },
  props: [
    'newFileDialog',
    'setNewFileDialog',
    'editingFile',
    'closeFileEditing',
    'saveFileEditing',
  ],
  data() {
    return {
      filenameErr: false,
      filenameErrMsg: '',

      newLinkFileDialog: false,
      newGoogleFileDialog: false,

      newGoogleDocumentType: '',
      newGoogleDocumentUrls: {
        doc: 'https://docs.google.com/document/create',
        sheet: 'https://docs.google.com/spreadsheets/create',
        draw: 'https://docs.google.com/drawings/create',
        jam: 'https://jamboard.google.com/create',
      },
      newGoogleDocumentNames: {
        doc: 'Document',
        sheet: 'Spread Sheet',
        draw: 'Drawing',
        jam: 'Jamboard',
      },

      newFileName: '',
      newFileLink: '',

      newFileType: 'link',

      icons: {
        mdiFileDocument,
        mdiArrowTopRight,
        mdiPlus,
        mdiDelete,
        mdiArrowLeft,
        mdiFolder,
      },

      newFileTypes: [
        [
          {
            icon: mdiFile,
            name: 'File Upload',
            disabled: true,
            action: () => {},
          },
        ], [
          {
            icon: mdiLink,
            name: 'Link to Document',
            action: () => {
              this.newLinkFileDialog = true
              this.setNewFileDialog(false)
            },
          },
        ], [
          {
            icon: mdiFileDocument,
            name: 'Google Docs',
            action: () => {
              this.newGoogleDocumentType = 'doc'
              this.newGoogleFileDialog = true
              this.setNewFileDialog(false)
            },
          }, {
            icon: mdiGoogleSpreadsheet,
            name: 'Google SpreadSheet',
            action: () => {
              this.newGoogleDocumentType = 'sheet'
              this.newGoogleFileDialog = true
              this.setNewFileDialog(false)
            },
          }, {
            icon: mdiDrawingBox,
            name: 'Google Drawing',
            action: () => {
              this.newGoogleDocumentType = 'draw'
              this.newGoogleFileDialog = true
              this.setNewFileDialog(false)
            },
          }, {
            icon: mdiPencilBox,
            name: 'Google Jamboard',
            action: () => {
              this.newGoogleDocumentType = 'jam'
              this.newGoogleFileDialog = true
              this.setNewFileDialog(false)
            },
          },
        ],
      ],
    }
  },
  methods: {
    closeLinkDialog() {
      this.newLinkFileDialog = false
      if (!this.editingFile) {
        this.setNewFileDialog(true)
      }
      this.closeFileEditing()
    },
    newFile() {
      this.setNewFileDialog(true)
    },
    addFileAction(type) {
      this.setNewFileDialog(false)
    },

    addLinkNewFileAction() {
      if (!this._validateFileName(this.newFileName)) {
        return
      }

      this.newGoogleFileDialog = false
      this.newLinkFileDialog = false
      this.setNewFileDialog(false)

      this._addFile(
        this.newFileName,
        this.newFileType || this.newGoogleDocumentType,
        this.newFileLink,
      )

      this.newFileName = ''
      this.newFileType = ''
      this.newGoogleDocumentType = ''
      this.newFileLink = ''
    },

    _addFile(name, type, custom_link) {
      this.$store.dispatch('addDocumentsFolderFile', {
        _new: true,
        name,
        type,
        custom_link,
      })
    },

    redirectToNewGoogleDocument() {
      if (!this._validateFileName(this.newFileName)) {
        return
      }

      let url = this.newGoogleDocumentUrls[this.newGoogleDocumentType]
      if (this.newFileName) {
        url += `?title=${this.newFileName}`
      }
      openNewTab(url)
    },

    _validateFileName(newFileName) {
      if (!newFileName) {
        this.__riseEmptyFilenameErr()

        return false
      }

      for (const file of this.currentFolder.files) {
        if (file.name === newFileName) {
          this.__riseFilenameExistsErr()

          return false
        }
      }

      return true
    },

    __riseEmptyFilenameErr() {
      this.filenameErr = true
      this.filenameErrMsg = 'No Filename Specified'
      setTimeout(() => {
        this.filenameErr = false
        this.filenameErrMsg = ''
      }, 5000)
    },
    __riseFilenameExistsErr() {
      this.filenameErr = true
      this.filenameErrMsg = 'File with same name already exists'
      setTimeout(() => {
        this.filenameErr = false
        this.filenameErrMsg = ''
      }, 5000)
    },

    saveFileEditingAction() {
      let file = this.editingFile
      file.name = this.newFileName
      file.custom_link = this.newFileLink
      file.type = this.newFileType

      this.newFileName = ''
      this.newFileLink = ''
      this.newFileType = ''

      this.saveFileEditing(file)
    }
  },
  computed: {
    ...mapState([
      'fileTypes',
      'currentFolder',
    ]),
  },
  watch: {
    editingFile(newVal) {
      if (newVal) {
        console.log(newVal)
        this.newLinkFileDialog = true
        this.newFileName = newVal.name
        this.newFileLink = newVal.custom_link
        this.newFileType = newVal.type
      } else {
        this.newLinkFileDialog = false
      }
    }
  }
}
</script>
