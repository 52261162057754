<template>
  <div>
    <v-row v-if="currentProject && currentProject.id">
      <v-dialog
        v-model="newFolderDialog"
        max-width="380px"
      >
        <v-card>
          <v-card-title>New Folder</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="newFolderName"
              class="mt-4"
              outlined
              label="Folder Name"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              text
              @click="newFolderDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="addFolderAction"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <new-file
        :editing-file="editingFile"
        :new-file-dialog="newFileDialog"
        :set-new-file-dialog="setNewFileDialog"
        :close-file-editing="closeFileEditing"
        :save-file-editing="saveFileEditing"
      ></new-file>

      <v-card
        class="col-12 mt-0"
        @contextmenu="_showFolderContext"
      >
        <v-card-title class="pa-1 ps-0">
          <v-btn
            v-if="currentFolder.parentFolder"
            rounded
            icon
            @click="openFolder(currentFolder.parentFolder.id)"
          >
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
          </v-btn>
          <span
            class="ml-3"
            :class="{'pt-3 pb-2': !currentFolder.parentFolder}"
          >{{ currentFolder.name }}</span>
        </v-card-title>

        <!--      <v-row>-->
        <!--        <v-col class="col-8">-->
        <!--          <v-breadcrumbs :items="currentDirectory.breadcrumbs" class="pa-4 ps-2"></v-breadcrumbs>-->
        <!--        </v-col>-->
        <!--      </v-row>-->

        <v-list
          nav

          dense
          class="mt-3"
        >
          <v-list-item-group color="primary">
            <v-row @contextmenu="_showFileContext">
              <v-divider class="col-12 pa-0"></v-divider>
              <h4 class="col-12">
                Folders

                <v-btn
                  depressed
                  class="pa-3 ml-3"
                  small
                  @click="newFolder"
                >
                  <v-icon small>
                    {{ icons.mdiPlus }}
                  </v-icon>
                  <span class="ml-2">Add Folder</span>
                </v-btn>
              </h4>

              <v-list-item
                v-if="currentFolder.folders && currentFolder.folders.length"
                v-for="(item, i) in currentFolder.folders"
                :key="'dir-' + i"
                :selectable="false"
                class="col-4 ma-0 mt-1 pa-2 list-element"
                :folder-id="item.id"
                @click="item._rename_mode ? (e) => editFolderAction(item, e) : openFolder(item.id)"
              >
                <v-icon
                  class="mr-3"
                  dense
                >
                  {{ getTypeIcon(item) || icons.mdiFolder }}
                </v-icon>
                <v-list-item-content>
                  <v-text-field
                    v-if="item._rename_mode"
                    v-model="item.name"
                    placeholder="Folder Name"
                    class="pa-0 inp-no-details"
                    dense
                    autofocus
                    @change="item._rename_mode ? (e) => editFolderAction(item, e) : () => {}"
                    @keyup.enter="(e) => editFolderAction(item, e)"
                  ></v-text-field>

                  <v-list-item-title v-if="!item._rename_mode">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <div class="w-full text-center mb-2" v-if="!currentFolder.folders || !currentFolder.folders.length">
                <span class="text-sm text--secondary">
                  No Folders Yet
                </span>
              </div>

              <v-divider class="col-12 pa-0 mt-6"></v-divider>
              <h4 class="col-12">
                Files
                <v-btn
                  depressed
                  class="pa-3 ml-3"
                  small
                  @click="newFile"
                >
                  <v-icon small>
                    {{ icons.mdiPlus }}
                  </v-icon>
                  <span class="ml-2">Add File</span>
                </v-btn>
              </h4>

              <v-list-item
                v-if="currentFolder.files && currentFolder.files.length"
                v-for="(item, i) in currentFolder.files"
                :key="'doc-' + i"
                :selectable="false"
                class="col-4 ma-0 mt-1 pa-2 list-element"
                :item-id="item.id"
                @click="item._rename_mode ? (e) => editFileAction(item, e) : openFile(item)"
                @contextmenu="_showFileContext"
              >
                <v-icon
                  class="mr-3"
                  dense
                >
                  {{ getTypeIcon(item) }}
                </v-icon>
                <v-list-item-content>
                  <v-text-field
                    v-if="item._rename_mode"
                    v-model="item.name"
                    placeholder="File Name"
                    class="pa-0 inp-no-details"
                    dense
                    autofocus
                    @change="item._rename_mode ? editFileAction(item) : () => {}"
                    @keyup.enter="editFileAction(item)"
                  ></v-text-field>

                  <v-list-item-title v-if="!item._rename_mode">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <div class="w-full text-center mb-4" v-if="!currentFolder.files || !currentFolder.files.length">
                <span class="text-sm text--secondary">
                  No Files Yet
                </span>
              </div>
            </v-row>
          </v-list-item-group>
        </v-list>
      </v-card>

      <v-menu
        v-model="contextMenu.show"
        :position-x="contextMenu.x"
        :position-y="contextMenu.y"
        absolute
        offset-y
      >
        <v-list class="pa-0">
          <v-list-item-group>
            <v-list-item
              v-for="(item, index) in contextMenu.actions"
              :key="index"
              class="col-12"
            >
              <v-list-item-title @click="item.menuAction()">
                <v-icon v-if="item.icon">
                  {{ item.icon }}
                </v-icon>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-row>

    <choose-project v-if="!currentProject || !currentProject.id"></choose-project>
  </div>
</template>

<script>
import {
  mdiArrowLeft,
  mdiFile,
  mdiPencil,
  mdiFolder,
  mdiDelete,
  mdiPlus,
  mdiFileDocument,
  mdiShare,
} from '@mdi/js'
import { mapState } from 'vuex'
import ChooseProject from '@/components/ChooseProject'

import NewFile from '@/components/Documents/NewFile'
import {copyToClipboard, openNewTab} from '@/helpers/helpers'
import router from "@/router";

export default {
  components: {
    NewFile,
    ChooseProject,
  },
  data() {
    return {
      editingFile: false,

      newFileDialog: false,

      newFolderDialog: false,
      newFolderName: '',

      icons: {
        mdiFileDocument,
        mdiPlus,
        mdiDelete,
        mdiArrowLeft,
        mdiFolder,
      },

      contextMenu: {
        x: 0,
        y: 0,
        show: false,
        actions: [],
        containerContextActions: [
          { title: 'New File', menuAction: this.newFile, icon: mdiPlus },
          { title: 'Create Folder', menuAction: this.newFolder, icon: mdiPlus },
        ],
        fileContextActions: [
          { title: 'Rename', menuAction: this.renameFile, icon: mdiPencil },
          { title: 'Edit', menuAction: this.editFile, icon: mdiPencil },
          { title: 'Delete', menuAction: this.deleteFile, icon: mdiDelete },
          { title: 'Copy Link', menuAction: this.copyToClipboardFile, icon: mdiShare },
        ],
        folderContextActions: [
          { title: 'Rename', menuAction: this.renameFolder, icon: mdiPencil },
          // { title: 'Edit', menuAction: this.editFolder, icon: mdiPencil },
          { title: 'Delete', menuAction: this.deleteFolder, icon: mdiDelete },
          // { title: 'Copy Link', menuAction: this.editFolder, icon: mdiShare },
        ],
      },
    }
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.$store.dispatch('setSectionOverlay', true)
        this.$store.dispatch('fetchFolder', this.$route.params.id)
        this.$store.dispatch('setSectionOverlay', false)
      },
      { immediate: true },
    )
  },
  watch: {
    async currentProject() {
      await this.$store.dispatch('setSectionOverlay', true)
      this.$store.commit('SET_CURRENT_FOLDER', {})
      await this.$store.dispatch('fetchFolder')
      await this.$store.dispatch('setSectionOverlay', false)
    },
  },
  methods: {
    getTypeIcon(elem) {
      for (const fileTypeElem of this.fileTypes) {
        if (fileTypeElem.type === elem.type) {
          return fileTypeElem.icon
        }
      }

      return mdiFile
    },

    copyToClipboardFile(item) {
      if (item.custom_link) {
        copyToClipboard(item.custom_link)
      }
    },

    openFile(item) {
      if (item.custom_link) {
        openNewTab(item.custom_link)
      }
    },
    openFolder(folderId) {
      if (!folderId) {
        // @TODO: rise error for wait for sync
        alert('Can not sync folder with server. Wait a little and re-try')
      }

      this.$router.push({
        name: 'documents',
        params: {
          id: folderId,
        },
      })
    },
    newFolder() {
      this.newFolderDialog = true
    },
    newFile() {
      this.newFileDialog = true
    },
    renameFile(id) {
      const file = this._getFileById(id || this.contextMenu.contextForFile)
      file._rename_mode = !file._rename_mode
    },
    renameFolder(id) {
      const folder = this._getFolderById(id || this.contextMenu.contextForFolder)
      folder._rename_mode = !folder._rename_mode
    },
    editFile(id) {
      const file = this._getFileById(id || this.contextMenu.contextForFile)
      file._editing_mode = true
      this.editingFile = file
    },
    editFolder(id) {
      const folder = this._getFolderById(id || this.contextMenu.contextForFolder)
      folder._editing_mode = true
    },
    editFileAction(file) {
      file._rename_mode = false
      file._editing_mode = false
      this.$store.dispatch('updateDocumentsFolderFile', file)
    },
    editFolderAction(folder, $event) {
      $event.stopPropagation()
      folder._rename_mode = false
      folder._editing_mode = false
      this.$store.dispatch('updateDocumentsFolderFolder', folder)
    },

    deleteFile(id) {
      const file = this._getFileById(id || this.contextMenu.contextForFile)
      file._deleted = true
      this.$store.dispatch('updateDocumentsFolderFile', file)
    },
    deleteFolder(id) {
      const folder = this._getFolderById(id || this.contextMenu.contextForFolder)
      folder._deleted = true
      this.$store.dispatch('updateDocumentsFolderFolder', folder)
    },

    addFolderAction() {
      if (!this.newFolderName) {
        alert('Folder name can not be empty')

        return
      }
      this.newFolderDialog = false

      this.$store.dispatch('addDocumentsFolderFolder', {
        _new: true,
        name: this.newFolderName,
        type: 'dir',
      })

      this.newFolderName = ''
    },

    addFileAction() {
      this.newFileDialog = false
    },

    _showFolderContext(e) {
      this.contextMenu.actions = this.contextMenu.containerContextActions
      this._showElemContext(e)
      e.stopPropagation()
    },
    _showFileContext(e) {
      const container = e.target.closest('.list-element')
      if (!container) return
      const contextForFile = container.__vue__.$attrs['item-id']

      if (contextForFile) {
        this.contextMenu.contextForFile = contextForFile
        this.contextMenu.actions = this.contextMenu.fileContextActions
      } else {
        this.contextMenu.contextForFolder = container.__vue__.$attrs['folder-id']
        this.contextMenu.actions = this.contextMenu.folderContextActions
      }

      this._showElemContext(e)
      e.stopPropagation()
    },
    _showElemContext(e) {
      e.preventDefault()
      this.contextMenu.show = false
      this.contextMenu.x = e.clientX
      this.contextMenu.y = e.clientY
      this.$nextTick(() => {
        this.contextMenu.show = true
      })
    },

    _getFileById(id) {
      for (const file of this.currentFolder.files) {
        if (file.id === id) {
          return file
        }
      }
    },

    _getFolderById(id) {
      for (const folder of this.currentFolder.folders) {
        if (folder.id === id) {
          return folder
        }
      }
    },

    setNewFileDialog(status) {
      this.newFileDialog = status
    },

    closeFileEditing() {
      this.editingFile = false
    },
    saveFileEditing(file) {
      this.$store.dispatch('updateDocumentsFolderFile', file)
      this.closeFileEditing()
    },
  },
  computed: {
    ...mapState([
      'currentProject',
      'currentFolder',
      'fileTypes',
    ]),
  },
  async mounted() {
    await this.$store.dispatch('setSectionOverlay', true)
    await this.$store.dispatch('fetchFolder', this.$route.params.id)
    await this.$store.dispatch('setSectionOverlay', false)
  },
}
</script>

<style>
  .inp-no-details .v-text-field__details {
    display: none;
  }
</style>
